import Container from "@vfuk/core-container";
import { Grid, GridColumn, GridRow } from "@vfuk/core-grid";
import Icon from "@vfuk/core-icon";
import classNames from "classnames";
import { useBusinessUnitsStore } from "hooks/stores";
import React, { useState } from "react";
import shallow from "zustand/shallow";
import { BusinessUnit } from "../../../api";
import { ReactComponent as GreenTick } from "../../../assets/svg/green_tick.svg";
import classes from "./TopBar.module.scss";

const TopBar = (): JSX.Element => {
  const businessUnits = useBusinessUnitsStore((state) => state.businessUnits);
  const [currentBU, setCurrentBU] = useBusinessUnitsStore(
    (state) => [state.currentBusinessUnit, state.setCurrentBusinessUnit],
    shallow
  );

  const [buListOpen, setBuListOpen] = useState(false);

  const toggleDropdown = () => {
    setBuListOpen(!buListOpen);
  };

  const closeDropdown = () => {
    setBuListOpen(false);
  };

  let timeoutId: ReturnType<typeof setTimeout>;
  const onBlurHandler = () => {
    timeoutId = setTimeout(() => {
      setBuListOpen(false);
    }, 10);
  };

  const onFocusHandler = () => {
    clearTimeout(timeoutId);
  };

  const changeBU = (bu: BusinessUnit) => {
    setCurrentBU(bu);
    closeDropdown();
  };

  return (
    <div className={classes.TopBar}>
      <Grid maxWidth="spring">
        <GridRow noGutters>
          <GridColumn offset={1}>
            <div
              className={classes.BUDropdownContainer}
              onBlur={onBlurHandler}
              onFocus={onFocusHandler}
            >
              <button onClick={toggleDropdown}>
                {currentBU?.Description}
                <Icon name={buListOpen ? "chevron-up" : "chevron-down"} inverse size={1} />
              </button>
              <div className={classNames(classes.Dropdown, { [classes.Open]: buListOpen })}>
                <Container elevation paddingLevel={0} allowOverflow={false}>
                  <div className={classes.BUDropdownOptions}>
                    {businessUnits.map((BU) => (
                      <div
                        className={classNames(classes.BUDropdownOption, {
                          [classes.Selected]: BU === currentBU,
                        })}
                        key={BU.Id}
                      >
                        <button onClick={() => changeBU(BU)}>
                          <span>{BU.Description}</span>
                          {BU === currentBU ? <GreenTick /> : null}
                        </button>
                      </div>
                    ))}
                  </div>
                </Container>
              </div>
            </div>
          </GridColumn>
        </GridRow>
      </Grid>
    </div>
  );
};

export default TopBar;
