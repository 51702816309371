const prod = {
  production: true,
  aws: {
    region: "eu-west-1",
    cognito: {
      userPoolId: "eu-west-1_U3ThfCb3l",
      userPoolAppClientId: "1s7or2gd49q2oqiu5i01qdo144",
      authDomain: "auth.vbcpcen.ucc.vodafone.com",
      authRedirectSignIn: "https://adminportal.vbcpcen.ucc.vodafone.com/",
      authRedirectSignOut: "https://adminportal.vbcpcen.ucc.vodafone.com/signout",
    },
    apiGateway: {
      basePath: "https://api.vbcpcen.ucc.vodafone.com/portal",
    },
  },
  framework: {
    archivedRoutingProfilePrefix: "DELETED",
  },
  externalLinks: {
    verint: "https://cen-mmr-v15.cenver.centrica.com/wfo",
  },
};

export default prod;
