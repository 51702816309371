import Button from "@vfuk/core-button";
import CalendarPicker from "@vfuk/core-calendar-picker";
import Container from "@vfuk/core-container";
import { Grid, GridColumn, GridRow } from "@vfuk/core-grid";
import Heading from "@vfuk/core-heading";
import Paragraph from "@vfuk/core-paragraph";
import Spacing from "@vfuk/core-spacing";
import Span from "@vfuk/core-span";
import Breadcrumbs from "components/Shared/Breadcrumbs/Breadcrumbs";
import FailureModalContent from "components/Shared/Modal/FailureModalContent/FailureModalContent";
import SuccessModalContent from "components/Shared/Modal/SuccessModalContent/SuccessModalContent";
import SectionBanner from "components/Shared/SectionBanner/SectionBanner";
import routes from "config/routes/routes";
import { useAuditStore } from "hooks/stores";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./AuditLogs.module.scss";

const AuditLogs = (): JSX.Element => {
  const { t } = useTranslation();

  const { call: getAuditLogs, loading: auditLoading } = useAuditStore(
    (state) => state.getAuditLogs
  );

  const [downloadAttempted, setDownloadAttempted] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDateBeforeEnd, setStartDateBeforeEnd] = useState<boolean>(true);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const today = new Date();
  const minDate = new Date();
  minDate.setMonth(today.getMonth() - 6);
  const maxDate = new Date();

  const changeStartDate = (newDate: Date | null) => {
    setStartDateError("");

    if (newDate) {
      setStartDate(newDate);

      if (endDate && newDate <= endDate) {
        setStartDateBeforeEnd(true);
      } else if (endDate) setStartDateBeforeEnd(false);
    }
  };

  const changeEndDate = (newDate: Date | null) => {
    setEndDateError("");

    if (newDate) {
      setEndDate(newDate);

      if (startDate && newDate >= startDate) {
        setStartDateBeforeEnd(true);
      } else if (startDate) setStartDateBeforeEnd(false);
    }
  };

  const downloadLogs = () => {
    if (startDate && endDate) {
      endDate.setHours(23, 59);
      getAuditLogs(startDate.toISOString(), endDate.toISOString())
        .then(() => {
          setDownloadSuccess(true);
        })
        .finally(() => setDownloadAttempted(true));
    }
  };

  const getState = (error: string): "error" | "info" | "success" | undefined => {
    if (!startDateBeforeEnd || error.length > 0) {
      return "error";
    } else return undefined;
  };

  const getContainerContents = () => {
    if (downloadAttempted) {
      if (downloadSuccess) {
        return (
          <SuccessModalContent
            heading={t("dashboard:audit.success.heading")}
            body={t("dashboard:audit.success.body", {
              start: startDate?.toLocaleDateString(),
              end: endDate?.toLocaleDateString(),
            })}
            confirmText={t("dashboard:audit.success.button")}
            onConfirm={() => {
              setStartDate(null);
              setEndDate(null);
              setDownloadAttempted(false);
              setDownloadSuccess(false);
            }}
          />
        );
      } else {
        return (
          <FailureModalContent
            heading={t("dashboard:audit.failure.heading")}
            body={t("dashboard:audit.failure.body", {
              start: startDate?.toLocaleDateString(),
              end: endDate?.toLocaleDateString(),
            })}
            onClose={() => {
              setDownloadAttempted(false);
              setDownloadSuccess(false);
            }}
            closeMessage={t("dashboard:audit.failure.button")}
          />
        );
      }
    } else {
      return (
        <>
          <Spacing spacingLevel={{ top: 2, bottom: 5 }}>
            <Heading level={5} weight={3}>
              {t("dashboard:audit.heading")}
            </Heading>
          </Spacing>

          <Spacing spacingLevel={{ bottom: 6 }}>
            <div className={classes.StartDate}>
              <Paragraph>{t("dashboard:audit.start_date")}</Paragraph>
              <CalendarPicker
                state={getState(startDateError)}
                id="start-date"
                name="startDate"
                value={startDate as unknown as Date}
                onChange={changeStartDate}
                onInvalidInputEntry={(error) => {
                  setStartDateError(error.toString());
                }}
                minDate={minDate}
                maxDate={maxDate}
                errorMessages={{
                  minDate: t("dashboard:audit.errors.min"),
                  maxDate: t("dashboard:audit.errors.max"),
                  includeDates: "",
                  excludeDates: "",
                  filterDate: "",
                }}
              />
            </div>
            {startDateError && (
              <Spacing spacingLevel={{ top: 1 }}>
                <Span appearance="secondary">
                  <Paragraph size={2}>{startDateError}</Paragraph>
                </Span>
              </Spacing>
            )}
          </Spacing>

          <Spacing spacingLevel={{ bottom: 7 }}>
            <div className={classes.EndDate}>
              <Paragraph>{t("dashboard:audit.end_date")}</Paragraph>
              <CalendarPicker
                state={getState(endDateError)}
                id="end-date"
                name="endDate"
                value={endDate as unknown as Date}
                onChange={changeEndDate}
                onInvalidInputEntry={(error) => {
                  setEndDateError(error.toString());
                }}
                minDate={minDate}
                maxDate={maxDate}
                errorMessages={{
                  minDate: t("dashboard:audit.errors.min"),
                  maxDate: t("dashboard:audit.errors.max"),
                  includeDates: "",
                  excludeDates: "",
                  filterDate: "",
                }}
              />
            </div>
            {endDateError && (
              <Spacing spacingLevel={{ top: 1 }}>
                <Span appearance="secondary">
                  <Paragraph size={2}>{endDateError}</Paragraph>
                </Span>
              </Spacing>
            )}
            {!startDateBeforeEnd && (
              <Spacing spacingLevel={{ top: 1 }}>
                <Span appearance="secondary">
                  <Paragraph size={2}>{t("dashboard:audit.errors.start_before_end")}</Paragraph>
                </Span>
              </Spacing>
            )}
          </Spacing>

          <Button
            appearance="primary"
            text={t("dashboard:audit.download")}
            state={!startDate || !endDate || !startDateBeforeEnd ? "disabled" : undefined}
            width="full"
            onClick={() => {
              downloadLogs();
            }}
            loading={auditLoading}
          />
        </>
      );
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { id: "dashboard", text: t("common:navigation.dashboard"), url: routes.dashboard },
          {
            id: "download-logs",
            text: t("dashboard:audit.breadcrumb"),
            url: routes.agents.list,
          },
        ]}
      />
      <SectionBanner
        section="audit"
        heading={t("dashboard:audit.banner_title")}
        subtitle={t("dashboard:audit.banner_subtitle")}
        iconName="download"
      />
      <Grid maxWidth="spring">
        <GridRow justify="center">
          <GridColumn col={12} colMd={8} colLg={6} className={classes.AuditLogContainer}>
            <Spacing spacingLevel={{ top: 6, bottom: 12 }}>
              <Container>{getContainerContents()}</Container>
            </Spacing>
          </GridColumn>
        </GridRow>
      </Grid>
    </>
  );
};

export default AuditLogs;
