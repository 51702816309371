import { Grid } from "@vfuk/core-grid";
import Heading from "@vfuk/core-heading";
import Icon from "@vfuk/core-icon";
import { IconNames } from "@vfuk/core-icon/dist/IconNames.types";
import Image from "@vfuk/core-image";
import Span from "@vfuk/core-span";
import auditBanner1x from "assets/images/audit_logs_banner/audit_log.jpg";
import auditBanner2x from "assets/images/audit_logs_banner/audit_log@2x.jpg";
import auditBanner3x from "assets/images/audit_logs_banner/audit_log@3x.jpg";
import flowsBanner1x from "assets/images/flow_config_banner/flow_config.jpg";
import flowsBanner2x from "assets/images/flow_config_banner/flow_config@2x.jpg";
import flowsBanner3x from "assets/images/flow_config_banner/flow_config@3x.jpg";
import usersBanner1x from "assets/images/user_config_banner/user_configuration.jpg";
import usersBanner2x from "assets/images/user_config_banner/user_configuration@2x.jpg";
import usersBanner3x from "assets/images/user_config_banner/user_configuration@3x.jpg";
import classNames from "classnames";
import { useMemo } from "react";
import classes from "./SectionBanner.module.scss";

type SectionBannerProps = {
  section: "flows" | "users" | "audit";
  heading: string;
  iconName: IconNames;
  subtitle?: string;
};

const SectionBanner = ({
  section,
  heading,
  subtitle,
  iconName,
}: SectionBannerProps): JSX.Element => {
  const bannerImage = useMemo(() => {
    switch (section) {
      case "flows":
        return (
          <Image
            alt="Flow config banner"
            sm={{
              src: flowsBanner1x,
              srcSet: {
                x1: flowsBanner1x,
                x2: flowsBanner2x,
                x3: flowsBanner3x,
              },
            }}
          />
        );
      case "audit":
        return (
          <Image
            alt="Audit log banner"
            sm={{
              src: auditBanner1x,
              srcSet: {
                x1: auditBanner1x,
                x2: auditBanner2x,
                x3: auditBanner3x,
              },
            }}
          />
        );
      case "users":
      default:
        return (
          <Image
            alt="User management banner"
            sm={{
              src: usersBanner1x,
              srcSet: {
                x1: usersBanner1x,
                x2: usersBanner2x,
                x3: usersBanner3x,
              },
            }}
          />
        );
    }
  }, [section]);

  return (
    <div className={classNames(classes.Banner, classes[section])} role="banner">
      <div className={classes.BannerImage}>{bannerImage}</div>
      <Grid maxWidth="spring" className={classes.BannerContent}>
        <div>
          <div className={classes.Icon}>
            <Icon name={iconName} size={4} />
          </div>
          <div>
            <Heading level={3} noMargin text={heading} />
            {subtitle && <Span>{subtitle}</Span>}
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default SectionBanner;
