import { Grid } from "@vfuk/core-grid";
import Icon from "@vfuk/core-icon";
import Link from "@vfuk/core-link";
import Spacing from "@vfuk/core-spacing";
import classNames from "classnames";
import { HasAnyPermission } from "components/Shared/Permissions";
import {
  flowConfigConfigPermission,
  flowConfigEgressPermission,
  flowConfigEPAPermission,
  flowConfigOperatingHoursPermission,
  flowConfigPermissions,
  userManagementPermissions,
} from "config/permissions/routePermissions";
import routes from "config/routes/routes";
import usePermissions from "hooks/usePermissions/usePermissions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RoutingTabs from "../Shared/RoutingTabs/RoutingTabs";
import classes from "./NavBar.module.scss";
import TopBar from "./TopBar/TopBar";

enum Subnav {
  ManageUsers = "ManageUsers",
  FlowConfiguration = "FlowConfiguration",
}

const NavBar = (): JSX.Element => {
  const { t } = useTranslation();
  const { hasAnyPermission } = usePermissions();

  const [openSubnav, setOpenSubnav] = useState<Subnav | undefined>(undefined);

  const getVisibleTabs = () => {
    const tabs = [];

    tabs.push({ name: t("common:navigation.dashboard"), route: "/", visible: true });

    tabs.push({
      name: t("common:navigation.configuration_flow"),
      route: "/flow-configuration",
      visible: hasAnyPermission(flowConfigPermissions) ? true : false,
      overrideClick: () => {
        setTimeout(() => {
          setOpenSubnav(Subnav.FlowConfiguration);
        }, 10);
      },
    });

    tabs.push({
      name: t("common:navigation.manage_users"),
      route: "/manage-users",
      visible: true,
      overrideClick: () => {
        setTimeout(() => {
          setOpenSubnav(Subnav.ManageUsers);
        }, 10);
      },
    });

    return tabs;
  };

  const closeSubnav = () => {
    setOpenSubnav(undefined);
  };

  let timeoutId: ReturnType<typeof setTimeout>;
  const onNavBlur = () => {
    timeoutId = setTimeout(() => {
      setOpenSubnav(undefined);
    }, 5);
  };

  const onSubNavFocus = () => {
    clearTimeout(timeoutId);
  };

  return (
    <>
      <TopBar />
      <nav className={classes.NavBar} onBlur={onNavBlur}>
        <Grid maxWidth="spring" className={classes.NavBarContent}>
          <Spacing spacingLevel={{ right: 9 }}>
            <Icon name="vodafone" size={5} />
          </Spacing>
          <RoutingTabs tabs={getVisibleTabs()} />
        </Grid>

        <div
          className={classNames(classes.SubNav, {
            [classes.Visible]: openSubnav === Subnav.ManageUsers,
          })}
          onFocus={onSubNavFocus}
        >
          <Grid maxWidth="spring" className={classes.SubNavGrid}>
            <HasAnyPermission permissions={userManagementPermissions}>
              <Link to={routes.agents.list} onClick={closeSubnav}>
                {t("common:navigation.user_configuration")}
              </Link>
            </HasAnyPermission>

            <Link to={routes.routingProfiles.list} onClick={closeSubnav}>
              {t("common:navigation.routing_management")}
            </Link>
          </Grid>
        </div>

        <div
          className={classNames(classes.SubNav, {
            [classes.Visible]: openSubnav === Subnav.FlowConfiguration,
          })}
          onFocus={onSubNavFocus}
        >
          <Grid maxWidth="spring" className={classes.SubNavGrid}>
            <HasAnyPermission permissions={flowConfigConfigPermission}>
              <Link to={routes.flowConfig.list} onClick={closeSubnav}>
                {t("common:navigation.configuration")}
              </Link>
            </HasAnyPermission>

            <HasAnyPermission permissions={[flowConfigEPAPermission]}>
              <Link to={routes.ingressInstance.list} onClick={closeSubnav}>
                {t("common:navigation.epa")}
              </Link>
            </HasAnyPermission>

            <HasAnyPermission permissions={flowConfigOperatingHoursPermission}>
              <Link to={routes.operatingHoursTemplates.list} onClick={closeSubnav}>
                {t("common:navigation.operating_hours")}
              </Link>
            </HasAnyPermission>

            <HasAnyPermission permissions={[flowConfigEgressPermission]}>
              <Link to={routes.egressInstance.list} onClick={closeSubnav}>
                {t("common:navigation.egress")}
              </Link>
            </HasAnyPermission>
          </Grid>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
