import Button from "@vfuk/core-button";
import Divider from "@vfuk/core-divider";
import { Grid, GridColumn, GridRow } from "@vfuk/core-grid";
import Heading from "@vfuk/core-heading";
import Link from "@vfuk/core-link";
import Overlay from "@vfuk/core-overlay";
import Paragraph from "@vfuk/core-paragraph";
import Spacing from "@vfuk/core-spacing";
import Span from "@vfuk/core-span";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as MSLogo } from "../../assets/svg/ms_logo.svg";
import { ReactComponent as VFLogo } from "../../assets/svg/vf_logo.svg";
import { useAuthStore } from "../../hooks/stores";
import SomethingWentWrongModal from "../Shared/Modal/SomethingWentWrongModal/SomethingWentWrongModal";
import classes from "./Login.module.scss";

const Login = (): JSX.Element => {
  const { t } = useTranslation();
  const signIn = useAuthStore((state) => state.signIn);

  const [showLandingPage] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const handleSignInClick = () => {
    setLoginClicked(true);
    signIn().catch(() => {
      setLoginClicked(false);
      setLoginError(true);
    });
  };

  // The screen currently doesn't render and instead triggers the sso on load
  useEffect(() => {
    signIn();
  }, []);

  return (
    <>
      {showLandingPage && (
        <div className={classes.Landing}>
          <Grid maxWidth="full">
            <GridRow justify="end">
              <GridColumn col={12} colMd={8} colLg={5} className={classes.WelcomeBlock}>
                <Overlay position="absolute" show zIndex={-1} />
                <Spacing spacingLevel={{ left: 10, right: 10 }}>
                  <VFLogo className={classes.VFLogo} />
                  <Spacing spacingLevel={{ bottom: 2, top: 3 }}>
                    <Heading level={2} size={3} noMargin text={t("login:welcome")} inverse />
                    <Heading level={1} size={4} noMargin text={t("login:title")} inverse />
                  </Spacing>
                  <Paragraph size={3}>
                    <Span inverse>{t("login:description")}</Span>
                  </Paragraph>
                  <Spacing spacingLevel={{ bottom: 6, top: 5 }}>
                    <div className={classes.LoginButtonContainer}>
                      <MSLogo className={classes.MSLogo} />
                      <Button
                        text={t("login:login_with_sso")}
                        appearance="secondary"
                        inverse
                        width="full"
                        onClick={handleSignInClick}
                        loading={loginClicked}
                      />
                    </div>
                  </Spacing>
                  <Divider />
                  <Span inverse display="block" className={classes.SupportLink}>
                    {t("login:issue_logging_in")}{" "}
                    <Link href="#" text={t("login:contact_support")} showUnderline inverse />
                  </Span>
                </Spacing>
              </GridColumn>
            </GridRow>
          </Grid>
          <SomethingWentWrongModal
            isOpen={loginError}
            onClose={() => {
              setLoginError(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default Login;
